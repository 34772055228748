.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #507234;
    padding: 10px 400px;
    height: 60px; 
}

.footer button {
    background: none;
    border: none;
    color: white;
    font-size: 1em; 
    cursor: pointer;
}

.footer button:hover {
    text-decoration: underline;
}

.footer .logoFooter {
    height: 60px;
    cursor: pointer;
}
@media (max-width: 1024px) {
    .footer {
        padding: 5px 50px;
    }
}
