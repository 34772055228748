

.servicesDisplay {
    display: flex;
    justify-content: space-between;
    gap: 30px;

}

.title {
    position: relative;
    display: flex;
  flex-direction: column;
  align-items: center;


    h3 {
        font-family: "Contrail One", sans-serif;
        color: #30401b;
        font-size: 1.8em;
        text-align: center;
        position: absolute;
        top: 5%;
        width: 100%;
        margin: 0px;

    }

    img {
        max-width: 100%;
        max-height: 350px;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.274);
        
    }



    li {
        list-style-type: none;
        text-align: center;
    }
}

@media (max-width: 1024px) {
    .content.services{
        margin:auto;

    }
    .servicesDisplay {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    
    }
    h3{
        top: 0px;
    }
    .title img {
        max-width: 90%;
        
    }

}