.headerCobater {
    font-family: "Contrail One", sans-serif;
    background-image: url("../img/header.jpg");
    background-size: cover;
    background-position: 50% 48%;
    height: 240px;
    position: relative;
    transition: background var(--transition-duration), height var(--transition-duration);
    overflow: hidden;
}

.headerCobater.scrolled {
    background-image: none;
}

.navbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: transparent;
    transition: background var(--transition-duration), padding var(--transition-duration);}

.navbar.scrolled {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.788);
    width: 100%;
    height: 50px;
    z-index: 10;

    .menu {
        color: #517238;
        padding-top: 15px;
    }

    .logo {
        padding-top: 0px;
    }
    a{
        text-decoration: none;
        color: #517238;
    }

}

.logo {
    position: absolute;
    top: 15px;
    left: 15px;
    max-width: 200px;
    cursor: pointer;

}

.liste {
    color: aliceblue;
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: 0px;
    left: 30%;
    width: 35%;

    li {
        margin-left: 10px;
        font-size: 1.4em;
        -webkit-text-stroke: 0.3px #517238;
    }
    a{
        text-decoration: none;
        color: white;
    }
    a:hover{
        color:#946475 ;
    }
    
}

.introduction {
    color: aliceblue;
    font-size: 1.6em;
    margin-left: 45%;
    margin-top: 75px;
    position: absolute;
    top: 45px;
    background-color: #5172383f;
    padding: 10px;
}

.introduction.scrolled {
    display: hidden;
}


.burger-icon {
    display: none;
}

@media (max-width: 1024px) {
    .headerCobater {
        height: 60px;


        .navbar {

            .logo {
                max-width: 100px;
                position: absolute;
                top: 0px;
            }

            .menu {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100vh;
                background-color: #517238;
                margin: 0;
                padding-top: 45px;
                padding-left: 0;
                left: 0;
                right: 0;
                top: 0;
                position: fixed;
                z-index: 22;
                overflow-y: auto;
                opacity: 0;
                transform: translateY(-100%);
                transition: transform 0.5s ease, opacity 0.5s ease;
            }

            .menu.open {
                opacity: 1;
                transform: translateY(0);
                position: fixed;
                top: 0;
                 left: 0;

            }
            .liste {
                display: flex;
                flex-direction: column;
                padding: 0;
                position: sticky;
                top: 50px; 
                background-color: #517238;
            }
        
            .liste li {
                text-align: center;
            }
        
            .liste a {
                color: white;
                display: block;
                padding: 10px 0; 
                transition: color 0.3s ease;
            }
        
            .liste a:hover {
                color: #946475;
            }
        }

            .menu.open li {
                opacity: 1;
                
            }
        }


        .introduction {
            display: block;
            white-space: normal;
            color: aliceblue;
            margin: 0;
            top: 15px;
            left: 40%;
            font-size: 0.7em;
            width: 80%;
            background-color: transparent;
        }

        .introduction .line {
            display: block;
            margin-left: 20px;
        }

        .introduction .line:first-child {
            margin-left: 0;
        }

        .burger-icon {
            background-image: url("../img/menu.png");
            background-size: cover;
            background-color: transparent;
            border: transparent;
            width: 30px;
            height: 30px;
            display: block;
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 5px;
            z-index: 23;
            transition: transform 0.3s ease-in-out, background-image 0.3s ease-in-out;

            &.open {
                transform: rotate(180deg);
                background-image: url("../img/close.png");
                width: 40px;
                height: 40px;
            }
        }
    }

    .navbar.scrolled {
        height: 20px;

        .burger-icon {
            right: 60px;
        }

    }


