.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}

.layout{
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  margin-top: 50px;


  .content{
    margin: 0 50px ;
    scroll-margin-top: 50px;
  }

  .footer{
    width: 100%;
  }
  
}
h2 {
  font-family: "Contrail One", sans-serif;
  text-align: center;
  font-weight: normal;
  background-color: #9464759d;
  border-radius: 12px;
  font-size: 2.3em;
  width: 230px;
  margin: 50px auto;
  color: black;
}
@media (max-width: 1024px) {

  .layout{
  margin-top: 5px;
  gap: 50px;


  .content{
    margin: 0 15px ;
  }
  }
  h2 {
    margin: auto;
    font-size: 1.5em;
  }



}