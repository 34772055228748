.contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.contact {
    margin-bottom: 50px;
    height: 100%;

}

h2 {
    width: 300px;
}

.infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
        margin-bottom: 0px;
    }

    p {
        margin-top: 5px;
    }

    h4 {
        margin: 10px;
    }

    a {
        color: black;
    }
}

.map {
    p {
        text-align: center;
    }
}

@media (max-width: 1024px) {

    .contact {
        margin-top: 50px;
        min-height: calc(100vh - 60px);
    }

    .contacts {
        flex-direction: column;
        margin: auto;
        max-width: 100%;
    }

    .map iframe {
        width: 100%;
        height: 300px;
    }

}