.products {

    padding: 2% 5%;

    h2 {

        margin-left: 150px;
    }
 

    .buttons {
        margin: 60px 50px 20px 50px;
        display: flex;
        gap: 20px;

        button {
            font-family: "Contrail One", sans-serif;
            font-size: 1em;
            align-self: center;
            background: linear-gradient(to bottom, #597d4e 0%, #3b5c2d 100%);
            color: white;
            padding: 10px 10px;
            border: 1px solid #314627;
            border-radius: 20px;
            text-shadow: 0 1px 0 #000;
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            transition: background 0.3s ease;
        }

        button:hover {
            background: linear-gradient(to bottom, #744857 0%, #946475 100%);
        }

        button.selected {
            background: linear-gradient(to bottom, #946475 0%, #946475 100%);
            box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.5);
        }
    }

    .productImg {
        max-width: 500px;
        height: 500px;
        box-shadow:0 5px 5px rgba(0, 0, 0, 0.37);
        object-fit: cover;
    }

    .productsText {
        display: flex;
        gap: 150px;

    }
    
    .descriptionProduct {
        height: 100%;
        max-width: 40%;
        align-self: center;
    }
}

@media (max-width: 1024px) {
    .products h2 {
        margin: auto;
    }

   .products .buttons {
        margin: 30px auto;;
        display: flex;
        align-items: center;
    }

    .products .buttons button {
        font-size: 10px;
margin: auto;
    }

    .products  .productsText {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        margin: auto;
        gap:20px;

    }

    .productsText .productImg{
        width: 350px;
        height: 350px;
    }

    .productsText .descriptionProduct       
       {
            max-width: 100%;
        }
  


}