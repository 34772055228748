.content.method{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    img {
        max-width: 100%;
        max-height: 450px;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.274);  
    }
    p{
        width: 50%;
    }

}
.slider {
    position: relative;
    width: 60%;
    height: 450px;
     display: flex;
    justify-content: center;
    align-items: center;
 
}
    
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 3rem;
    color: #507234;
    cursor: pointer;
    z-index: 10;
  }
  .right-arrow:hover{
        color: #946475;
  }

  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 10px;
    font-size: 3rem;
    color: #507234;
    cursor: pointer;
    z-index: 10;

  }
  .left-arrow:hover{
    color: #946475;
}
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1.2s;
    transform: none;
  }
  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}
  @media (max-width: 1024px) {
    .right-arrow {

        height: 30px;
        right: -40px;

      }
      .left-arrow {
        height: 30px;
        left: -40px;


      }
      .slider {
        width: 80%;
   
    }
    button {
      width: 500px;
    }
    }
