.story {
    color: red;
    display: flex;
    justify-content: space-between;
    padding: 2% 5%;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: 40%;


}

.storyText {

    font-size: 1em;
    position: static;
    color: black;

}

.platformImg {
    max-width: 43%;
    align-self: center;
    box-shadow:0 4px 5px rgba(0, 0, 0, 0.274);

}

.button {
    font-family: "Contrail One", sans-serif;
    font-size: 1em;
    width: 110px;
    align-self: center;
    background: linear-gradient(to bottom, #597d4e 0%, #3b5c2d 100%);
    color: white;
    padding: 10px 10px;
    border: 1px solid #314627;
    border-radius: 20px;
    text-shadow: 0 1px 0 #000;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background 0.3s ease;
}

.button:hover {
    background: linear-gradient(to bottom, #3b5c2d 0%, #597d4e 100%);
}


@media (max-width: 1024px) {
    .story{
        flex-direction: column;
        gap: 25px;
        padding: 10px;
        align-items: center;
    }
    .text{
        max-width: 100%;
        h2{
            width: 250px;

        }
    }
    .buttonStory {
        width: 30%;
    }
    .platformImg {
        max-width: 100%;
        height: auto;
        box-shadow:0 4px 5px rgba(0, 0, 0, 0.274);
    }

  
  }