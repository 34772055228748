/* CSS standard sans imbrication */
.methods {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    margin-bottom: 150px;
}

.content.method.pageComplete {
    margin-bottom: 0px;
}

.content.method.pageComplete h3 {
    text-decoration: underline;
}

.content.method.pageComplete p {
    margin-bottom: 0;
}

.methods .stepOne {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.methods .stepOneTxt {
    display: flex;
}

.methods .stepOneTxt img {
    max-width: 600px;
    height: auto;
    object-fit: cover;
}

.methods .stepOneTxt p {
    width: 70%;
    margin-left: 25px;
    align-self: center;
}

.methods .stepOneList {
    text-align: center;
}

.methods .stepOneList p {
    text-decoration: underline;
    font-weight: bold;
}

.methods .stepOneList li {
    list-style: none;
    margin-bottom: 5px;
}

.methods .stepOneList ul {
    margin-bottom: 40px;
}

.methods .stepOneList img {
    max-width: 600px;
}

.methods .steptwo {
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
}

.methods .stepTwoContent{
    display: flex;
justify-content: center;
align-items: center;
}

.methods .stepTwoContent p{
width: 40%;

}

.methods .steptwo img {
    max-width: 600px;
    margin: 30px;

}


.methods .stepThree img{
    max-width: 550px;
}


.methods .stepThree, 
.methods .stepFour  {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    }

.methods .stepThree img,
.methods .stepFive img{
    max-width: 600px;
    margin: 10px;
}
.methods .stepThree p
{
    max-width: 60%;
    text-align: center;
}

.methods .stepFour p {
    width: 80%;
}

.methods .stepFive{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.methods .stepFive .stepFiveContent{
    display: flex;
align-items: center;
}



@media (max-width: 1024px) {
    .methods {
        margin-top: 50px;
        min-height: calc(100vh - 60px);
        gap: 30px;
        margin-bottom: 50px;
    }

    .methods .stepOneTxt {
        flex-direction: column;
        align-items: center;
    }

    .methods .stepOneTxt p {
        width: 90%;
        margin-left: 0;
    }

 
    .methods  img
     {
        width: 90%;
    }

    .methods .stepOneList {
        margin-top: 0px;
    }

    .methods .stepOneList ul {
        margin-bottom: 20px;
    }

    .methods .stepTwoContent{
        display: flex;
        flex-direction: column;
    justify-content: center;
    align-items: center;
    }
    
    .methods .stepTwoContent p{
    width: 100%;
    
    }
    
    .methods .stepFiveContent{
        display: flex;
        flex-direction: column;
    justify-content: center;
    align-items: center;
    }
    .methods .stepFiveContent p {
        width: 90%;
    }
}